@import '../../styles/constants';

.HomeView {
  @include center-content;
  flex-direction: column;
  min-height: 100%;

  @media (max-width: 550px) {
    flex-direction: column-reverse;
  }
}
