@import '../../styles/constants';

.ContentCard {
  padding: 50px;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &_heading {
    width: 100%;
    text-align: center;
    color: $accent-color;

    & h2 {
      font-weight: 500;
      font-size: 2rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 40px;
      user-select: none;
    }
  }

  &_content {
    display: flex;
    justify-content: space-between;
    color: $accent-color;
  }

  @media (max-width: $breakpoint-md) {
    padding: 30px;

    &_heading h2 {
      font-size: 1.5rem;
    }
  }
}
