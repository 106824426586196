@import '../../styles/constants';

.ContactButton {
  @include center-content;

  &_link {
    position: absolute;
    bottom: 6rem;
    padding: 0.7rem;
    background: $secondary-color;
    color: $accent-color;
    border-radius: 3px;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    opacity: 0;
    animation: fade 0.5s ease forwards 4s;
    user-select: none;
    cursor: pointer;

    @media (min-width: $breakpoint-xs) {
      font-size: 1.3rem;
    }

    @media (min-width: 550px) {
      position: relative;
      margin-top: 10rem;
    }
  }
}
