@import '../../styles/constants';

.TimelineItem {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin: 10px 0;
  width: 100%;

  &_content {
    width: 400px;
    max-width: 95%;
    border-radius: 5px;
    box-shadow: 0 0 10px transparentize($accent-color, 0.7);
    background: $darker-color;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    text-align: right;
    position: relative;
    margin-left: 0;
    margin-right: 5px;

    &::after {
      content: '';
      box-shadow: 1px -1px 1px transparentize($accent-color, 0.8);
      background: $darker-color;
      width: 15px;
      height: 15px;
      transform: rotate(45deg);
      position: absolute;
      right: -7.5px;
      top: calc(50% - 7.5px);
    }

    &_tag {
      color: $accent-color;
      font-size: 0.7rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 600;
      background-color: $secondary-color;
      padding: 5px;
      position: absolute;
      top: 7.5px;
      left: 7.5px;
      user-select: none;
    }

    &_circle {
      @include center-content;
      width: 35px;
      height: 35px;
      background-color: $primary-color;
      border: 3px solid $secondary-color;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 15px);
      right: -53px;
      z-index: 200;

      &_icon {
        margin-bottom: 1px;
        margin-right: 1px;
      }
    }

    small {
      color: #ccc;
      margin-top: 22px;
      user-select: none;
    }

    p,
    strong {
      font-size: 0.9rem;
      line-height: 1.5rem;
      margin: 5px 0;
      max-width: 380px;
      text-align: justify;
      text-justify: inter-word;
      user-select: none;
    }

    strong {
      text-decoration: underline;
    }
  }

  @media (max-width: $breakpoint-lg) {
    &_content {
      padding: 15px 10px;
      text-align: center;
      align-items: center;

      &_tag {
        width: calc(100% - 15px);
      }

      small {
        margin-top: 22px;
      }
    }

    &:nth-child(odd) {
      .TimelineItem_content {
        padding: 15px 10px;
        text-align: center;
        align-items: center;
      }
    }
  }

  @media (min-width: $breakpoint-xs) {
    &_content {
      &_tag {
        font-size: 0.75rem;
      }
      p,
      strong {
        font-size: 1rem;
      }
    }
  }

  @media (min-width: $breakpoint-s) {
    width: 50%;

    &:nth-child(odd) {
      align-self: flex-end;
      justify-content: flex-start;
      padding-right: 0;
      padding-left: 30px;

      .TimelineItem_content {
        text-align: left;
        align-items: flex-start;
        margin-right: 0;
        margin-left: 5px;

        &::after {
          right: auto;
          left: -7.5px;
          box-shadow: -1px 1px 1px transparentize($accent-color, 0.8);
        }

        &_tag {
          left: auto;
          right: 7.5px;
        }

        &_circle {
          right: auto;
          left: -53px;
        }
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    &_content {
      max-width: 80%;
    }
  }

  @media (min-width: $breakpoint-xl) {
    &_content small {
      margin: 5px 0;
    }
  }
}
