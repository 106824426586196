@import '../../styles/constants';

.Presentation {
  &_info {
    @include center-content;
    color: $accent-color;
    font-size: 1.3rem;
    font-weight: 900;
    flex-direction: column;

    &_text {
      user-select: none;
    }
  }

  @media (min-width: $breakpoint-xs) {
    &_info {
      font-size: 1.8rem;
    }
  }

  @media (min-width: 550px) {
    margin-top: 10rem;
  }

  @media (min-width: 630px) {
    &_info {
      flex-direction: row;
    }
  }

  @media (min-width: $breakpoint-lg) {
    &_info {
      font-size: 2.2rem;
    }
  }

  @media (min-width: $breakpoint-xl) {
    &_info {
      font-size: 2.7rem;
    }
  }
}
