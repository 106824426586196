@import '../../styles/constants';

.ContactImage {
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $secondary-color;
    transform-origin: bottom;
    transform: skew(-25deg) translateX(10%);
  }

  &_image {
    position: absolute;
    bottom: 0;
    max-height: 95%;
    margin-left: 3rem;
  }

  @media (max-width: $breakpoint-lg) {
    display: none;
  }
}
