@import '../../styles/constants';

.SkillsWordCloud {
  @include center-content;
  align-items: flex-start;
  margin: auto;

  text {
    user-select: none;
  }

  @media (max-width: 1700px) {
    margin-right: 150px;
  }

  @media (max-width: 1550px) {
    margin-right: 100px;
  }

  @media (max-width: 1450px) {
    margin-right: 50px;
  }
}
