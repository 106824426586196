@import '../../styles/constants';

.NavbarMenuToggler {
  width: 3rem;
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 650ms ease-out;
  z-index: 1500;
  cursor: pointer;
  position: fixed;
  top: 1.3rem;
  right: 1.3rem;

  &.open {
    transform: rotate(-45deg);

    .start {
      transform: rotate(-450deg) translateX(0.8rem);
    }

    .end {
      transform: rotate(-450deg) translateX(-0.8rem);
    }
  }

  &_bar {
    background: linear-gradient(to right, $accent-color, $secondary-color);
    width: 100%;
    height: 4px;
    border-radius: 0.8rem;

    &.half {
      width: 50%;
    }

    &.start {
      transform-origin: right;
      transition: transform 650ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    }

    &.end {
      align-self: flex-end;
      transform-origin: left;
      transition: transform 650ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    }
  }

  @media (max-width: $breakpoint-xxs) {
    width: 2.5rem;
    height: 1.5rem;
  }
}
