@import '../../../styles/constants';

.FlagButton {
  height: 40px;
  width: 40px;
  cursor: pointer;
  margin: 0 10px;
  user-select: none;

  &_image {
    height: 100%;
    width: 100%;
    border-radius: 50%;

    &.active {
      box-shadow: 0px 0px 8px transparentize($accent-color, 0.5);
      transform: scale(1.15);

      &:hover {
        transform: scale(1.15);
      }
    }

    &:hover {
      transform: scale(1.15);
    }
  }

  @media (max-width: $breakpoint-xl) {
    height: 35px;
    width: 35px;
  }

  @media (max-width: $breakpoint-xxs) {
    width: 30px;
    height: 30px;
  }
}
