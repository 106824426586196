@import '../../styles/constants.scss';

.AnimatedBackground {
  background: $primary-color;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: -1;

  &_box-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &_box {
      position: absolute;
      display: block;
      list-style: none;
      width: 25px;
      height: 25px;
      background: transparentize($accent-color, 0.8);
      animation: animate-background 30s linear infinite;
      bottom: -500px;

      &.box-1 {
        left: 86%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
      }

      &.box-2 {
        left: 12%;
        width: 30px;
        height: 30px;
        animation-delay: 1.5s;
        animation-duration: 20s;
      }

      &.box-3 {
        left: 70%;
        width: 100px;
        height: 100px;
        animation-delay: 5.5s;
      }

      &.box-4 {
        left: 42%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 25s;
      }

      &.box-5 {
        left: 65%;
        width: 40px;
        height: 40px;
        animation-delay: 0s;
      }

      &.box-6 {
        left: 15%;
        width: 110px;
        height: 110px;
        animation-delay: 3.5s;
        animation-duration: 20s;
      }
    }
  }
}
