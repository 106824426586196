@import '../../styles/constants';

.TerminalAnimation {
  @include center-content;
  margin: 1.3rem;
  background: linear-gradient(to right, $accent-color, $secondary-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  font-size: 1.3rem;
  user-select: none;

  &_cursor {
    height: 1.5rem;
    width: 0.5rem;
    margin-left: 0.3rem;
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1.5s blink step-end infinite;
  }

  @media (max-width: $breakpoint-xxs) {
    font-size: 1rem;
  }
}
