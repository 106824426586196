@import '../../styles/constants';

.ContactForm {
  @include center-content;
  width: 50%;

  &_form {
    position: relative;
    width: 100%;
    padding: 0 0 0 75px;

    &_title {
      color: $accent-color;
      font-weight: 500;
      font-size: 2rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 40px;
      user-select: none;
    }

    &_input-box {
      position: relative;
      margin-bottom: 40px;

      & textarea {
        height: 75px;
      }

      &.submit {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &_input {
      position: relative;
      background: transparent;
      outline: none;
      border: none;
      width: 100%;
      color: $accent-color;
      padding-bottom: 10px;
      font-size: 1.2rem;
      letter-spacing: 1px;
      font-weight: 300;
      border-bottom: 2px solid transparentize($accent-color, 0.5);
      resize: none;

      &:focus ~ .ContactForm_form_label {
        transform: translateY(-24px);
        color: $secondary-color;
      }

      &:valid ~ .ContactForm_form_label {
        transform: translateY(-24px);
        color: $secondary-color;
      }

      &:focus {
        border-bottom-color: $accent-color;
      }

      &:valid {
        border-bottom-color: $accent-color;
      }
    }

    &_button {
      padding: 8px 20px;
      margin-top: -10px;
      min-width: 100px !important;
      background: $secondary-color !important;
      color: $accent-color;
      font-size: 1.3rem;
      font-weight: 400;
      border: none !important;
      border-radius: 3px;
      text-transform: uppercase;
      letter-spacing: 4px;
      user-select: none;
      cursor: pointer;

      &:focus {
        background: $primary-color !important;
        color: $secondary-color;
      }

      &_icon {
        color: $darker-color;
      }
    }

    &_message {
      color: $secondary-color;
      font-size: 1.1rem;
      font-weight: 700;
    }

    &_label {
      position: absolute;
      left: 0;
      font-weight: 300;
      font-display: inline-block;
      pointer-events: none;
      letter-spacing: 1px;
      color: transparentize($accent-color, 0.5);
      transition: all 0.2s;
      user-select: none;

      & .error::after {
        color: $secondary-color;
        content: ' required';
      }
    }
  }

  @media (max-width: $breakpoint-lg) {
    width: 100%;
    padding: 0 50px;

    &_form {
      width: 100%;
      max-width: 500px;
      padding: 0;
    }
  }

  @media (max-width: $breakpoint-xs) {
    &_input-box.submit {
      flex-direction: column;
    }

    &_button {
      margin: auto;
    }

    &_message {
      margin: auto;
      margin-top: 0.3rem;
    }
  }

  @media (max-width: $breakpoint-xxs) {
    &_title {
      font-size: 1.3rem;
    }

    &_input {
      font-size: 1rem;
    }

    &_label {
      font-size: 0.8rem;
    }
  }
}
