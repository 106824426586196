@import './styles/constants';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  /* Scrollbar */
  /* width */
  ::-webkit-scrollbar {
    width: 6px;

    /* Track */
    &-track {
      background: $darker-color;
    }

    /* Handle */
    &-thumb {
      background: $secondary-color;

      /* Handle on hover */
      &:hover {
        background: $secondary-color;
      }
    }
  }

  scrollbar-color: $secondary-color $darker-color; /* thumb and track color */
  scrollbar-width: thin;
}

body {
  font-family: $primary-font;
  font-size: 1rem;
  line-height: 1.6;
  background-color: $primary-color;
}

a {
  text-decoration: none;
}

.App {
  height: 100vh;
}
