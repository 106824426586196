@import '../../styles/constants';

.NavbarLogo {
  margin-left: 5px;
  height: 70px;
  width: 70px;

  &_image {
    height: 100%;
    width: 100%;
    margin-top: 3px;
  }

  @media (max-width: $breakpoint-lg) {
    &_image {
      margin-top: 0;
    }
  }

  @media (max-width: $breakpoint-xxs) {
    height: 60px;
    width: 60px;
  }
}
