@import '../../styles/constants';

.NavbarItem {
  list-style: none;
  margin: 0 25px;
  transition: 0.5s;

  &_link {
    display: block;
    position: relative;
    text-decoration: none;
    padding: 0.3rem;
    font-size: 1.1rem;
    color: $accent-color;
    text-transform: uppercase;
    transition: 0.5s;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
      transform: scale(1.8);
      opacity: 1 !important;

      &::before {
        transition: transform 0.5s;
        transform-origin: left;
        transform: scaleX(1);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $secondary-color;
      transition: transform 0.5s;
      transform-origin: right;
      transform: scaleX(0);
      z-index: -1;
    }
  }

  @media (max-width: $breakpoint-xl) {
    margin: 7px 0;
  }
}
