@import '../../styles/constants';

.SkillsInfo {
  @include center-content;
  width: 100%;
  display: flex;
  justify-content: space-around;

  &_column {
    font-size: 1.15rem;
  }

  &_row {
    display: flex;
    justify-content: flex-start;
    margin: 15px;

    & p {
      user-select: none;
    }
  }

  &_icon {
    color: $secondary-color;
    margin: auto 0;
    margin-right: 10px;
  }

  @media (max-width: $breakpoint-xl) {
    padding-right: 0;
  }

  @media (max-width: $breakpoint-ms) {
    flex-direction: column;
  }

  @media (max-width: $breakpoint-s) {
    &_column {
      font-size: 1rem;
      padding: 7px;
    }
  }

  @media (max-width: $breakpoint-xs) {
    &_column {
      font-size: 0.9rem;
    }
  }

  @media (max-width: $breakpoint-xxs) {
    &_column {
      font-size: 0.7rem;
    }
  }
}
