@import '../../../styles/constants';

.DeveloperAnimationEn {
  margin-left: 0.7rem;
  margin-bottom: -1rem;

  #developerEn {
    width: 280px;
    height: 54px;
    animation: fill-developer 0.5s ease forwards 3.5s;
  }

  #developerEn path:nth-child(1) {
    stroke-dasharray: $letter-f-en;
    stroke-dashoffset: $letter-f-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(2) {
    stroke-dasharray: $letter-u-en;
    stroke-dashoffset: $letter-u-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(3) {
    stroke-dasharray: $letter-l-en;
    stroke-dashoffset: $letter-l-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(4) {
    stroke-dasharray: $letter-l-en;
    stroke-dashoffset: $letter-l-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(5) {
    stroke-dasharray: $dash-en;
    stroke-dashoffset: $dash-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(6) {
    stroke-dasharray: $letter-s-en;
    stroke-dashoffset: $letter-s-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(7) {
    stroke-dasharray: $letter-t-en;
    stroke-dashoffset: $letter-t-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(8) {
    stroke-dasharray: $letter-a-en;
    stroke-dashoffset: $letter-a-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(9) {
    stroke-dasharray: $letter-c-en;
    stroke-dashoffset: $letter-c-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(10) {
    stroke-dasharray: $letter-k-en;
    stroke-dashoffset: $letter-k-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(11) {
    stroke-dasharray: $letter-d-en;
    stroke-dashoffset: $letter-d-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(12) {
    stroke-dasharray: $letter-e-en;
    stroke-dashoffset: $letter-e-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(13) {
    stroke-dasharray: $letter-v-en;
    stroke-dashoffset: $letter-v-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(14) {
    stroke-dasharray: $letter-e-en;
    stroke-dashoffset: $letter-e-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(15) {
    stroke-dasharray: $letter-l-en;
    stroke-dashoffset: $letter-l-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(16) {
    stroke-dasharray: $letter-o-en;
    stroke-dashoffset: $letter-o-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(17) {
    stroke-dasharray: $letter-p-en;
    stroke-dashoffset: $letter-p-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(18) {
    stroke-dasharray: $letter-e-en;
    stroke-dashoffset: $letter-e-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(19) {
    stroke-dasharray: $letter-r-en;
    stroke-dashoffset: $letter-r-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  #developerEn path:nth-child(20) {
    stroke-dasharray: $dot-en;
    stroke-dashoffset: $dot-en;
    animation: appear 2.5s ease forwards 0.5s;
  }

  @media (min-width: $breakpoint-xs) {
    #developerEn {
      width: 406.4px;
    }
  }

  @media (min-width: $breakpoint-lg) {
    margin-bottom: -1.1rem;
    #developerEn {
      width: 500px;
    }
  }

  @media (min-width: $breakpoint-xl) {
    margin-bottom: -1.4rem;
  }
}
