@import "../../styles/constants";

.AboutInfo {
  @include center-content;
  flex-direction: column;
  padding-right: 50px;
  text-align: justify;
  text-justify: inter-word;

  &_content {
    max-height: 625px;
    overflow: auto;
  }

  &_heading {
    font-size: 1.3rem;
    margin-bottom: 10px;
    user-select: none;
  }

  &_info {
    font-size: 1.15rem;
    user-select: none;

    & i,
    br,
    p,
    strong {
      user-select: none;
    }
  }

  @media (max-width: $breakpoint-xl) {
    padding-right: 0;
  }

  @media (max-width: $breakpoint-s) {
    &_heading {
      font-size: 1.1rem;
    }

    &_info {
      font-size: 1rem;
    }
  }
}
