@import '../../styles/constants';

.Navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: $darker-color;
  position: fixed;
  z-index: 500;

  .NavbarMenuToggler {
    position: relative;
    visibility: hidden;
  }

  @media (max-width: $breakpoint-lg) {
    .TerminalAnimation {
      display: none;
    }
  }
}
