@import "../../styles/constants";

.AboutView {
  @include center-content;
  height: 100%;
  background: $primary-color;
  padding-top: 75px;

  & .ContentCard_content {
    max-width: 1300px;
    margin: auto;
  }

  @media (max-width: $breakpoint-xl) {
    .ContentCard_content {
      flex-direction: column-reverse;
    }
  }
}
