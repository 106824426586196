@import '../../styles/constants';

.ContactView {
  @include center-content;
  height: 100%;
  background: $primary-color;
  padding-top: 75px;
  overflow: hidden;

  .ContactCard {
    margin: auto;
    margin-bottom: 80px;
  }
}
