@import '../../styles/constants';

.NavbarMenu {
  @include center-content;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  background-color: $darker-color;
  position: fixed;
  top: -100vh;
  border-bottom-right-radius: 100%;
  border-bottom-left-radius: 100%;
  transition: all 650ms cubic-bezier(1, 0, 0, 1);
  z-index: 1000;

  &.open {
    top: 0;
    border-radius: initial;
  }

  &_list {
    margin: 0;
    padding: 0;
    display: flex;

    &:hover {
      .NavbarItem_link {
        opacity: 0.2;
        background-color: transparent;
      }
    }
  }

  .resume {
    background-color: $secondary-color;
  }

  @media (max-width: $breakpoint-xl) {
    &_list {
      flex-direction: column;
    }
  }
}
