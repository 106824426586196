@import '../../styles/constants';

.AboutImage {
  @include center-content;
  align-items: flex-start;
  margin: 3rem 0;
  position: relative;

  &::before {
    position: relative;
    content: '';
    width: 350px;
    height: 350px;
    background-color: $secondary-color;
    top: 25px;
    left: 25px;
    border-radius: 5px;
  }

  &_image {
    height: 350px;
    width: 350px;
    position: absolute;
    border-radius: 5px;
  }

  @media (max-width: $breakpoint-xl) {
    margin-bottom: 5rem;
  }

  @media (max-width: $breakpoint-md) {
    &::before {
      height: 250px;
      width: 250px;
      top: 15px;
      left: 15px;
    }

    &_image {
      height: 250px;
      width: 250px;
    }
  }
}
