@import '../../styles/constants';

.SkillsView {
  @include center-content;
  height: 100%;
  background: $primary-color;
  padding-top: 75px;

  @media (max-width: $breakpoint-xl) {
    .ContentCard_content {
      flex-direction: column-reverse;
    }

    .SkillsWordCloud {
      margin: auto;
    }
  }
}
