@import '../../styles/constants';

.Timeline {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;

  &::after {
    background-color: $secondary-color;
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    left: 100%;
  }

  @media (min-width: $breakpoint-s) {
    &::after {
      left: calc(50% - 2px);
    }
  }
}
